/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import { customSelectStyles, filterConfig } from '../helpers/constants';
import { AppContext } from '../context/AppContext';

type OptionType = {
  value: string;
  label: string;
};

function SearchBox() {
  const appState = useContext(AppContext);
  const [selected, setSelected] = useState<any>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>('');

  const navigate = useNavigate();

  const onSearch = () => {
    const filters = [];

    if (selected) {
      if (selected.type === 'Specialities') {
        filters.push(`slctInterest=${encodeURIComponent(selected.value)}`);
      }
      if (selected.type === 'Destinations') {
        filters.push(`slctDestination=${selected.label}`);
      }
    }
    window.scrollTo(0, 0);
    navigate(`/find-an-elite-travel-agent?${filters.join('&')}`);
    navigate(0);
  };

  const groupedSpecialities = () => {
    const items = [];
    // items.push({
    //   label: 'Travel Specialities',
    //   options: [...appState.specialities.reduce((map: any, obj: any) => map.set(obj.interest, obj), new Map()).values()].map((x) => ({
    //     value: x.interest,
    //     label: x.interest,
    //     type: 'Specialities'
    //   }))
    // });
    items.push({
      label: 'Destinations',
      options: appState.destinations.map((x) => ({
        value: x.destinationId,
        label: x.destination,
        type: 'Destinations'
      }))
    });
    return items;
  };

  const optionRenderer = (option: OptionType) => {
    if (currentValue === '') return option.label;
    const regex = new RegExp(currentValue, 'gi');
    let output = option.label.replace(regex, `<b>$&</b>`);
    return <div dangerouslySetInnerHTML={{ __html: output }} />;
  };

  useEffect(() => {
    if (selected) {
      onSearch();
    }
  }, [selected]);

  return (
    <div className="md:shadow-2xl px-2 md:px-6 py-6 gap-4 z-50 flex flex-col w-full rounded-sm">
      <div className="flex-1 flex justify-center">
        <h2 className="text-black text-xl md:text-2xl  font-normal text-center">
          Find an <b>expert travel advisor</b>. Where would you like to go?
        </h2>
      </div>
      <div className="flex flex-col md:flex-row  gap-8 md:gap-0 flex-1 justify-center w-full items-center">
        <div className="flex-1 min-w-full md:min-w-max">
          <Select
            styles={customSelectStyles}
            className=" w-full  text-sm z-50"
            options={groupedSpecialities()}
            components={{ DropdownIndicator: () => null }}
            onChange={(val) => {
              setSelected(val);
              setIsMenuOpen(false);
            }}
            onInputChange={(val) => {
              setCurrentValue(val);
              if (val !== '') {
                setIsMenuOpen(true);
              } else setIsMenuOpen(false);
            }}
            placeholder="Search by destination"
            value={selected}
            filterOption={createFilter(filterConfig)}
            menuIsOpen={isMenuOpen}
            noOptionsMessage={({ inputValue }) => !inputValue || 'Sorry, no matching results'}
            formatOptionLabel={optionRenderer}
            aria-label="Destination Expert"
            aria-live="off"
            // components={{ DropdownIndicator: () => null }}
          />
        </div>
        <div className="">
          <button className="default-btn h-[2.8rem] py-1" onClick={() => onSearch()}>
            PLAN A TRIP
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchBox;
