//@ts-nocheck
import React from 'react';

export const SunIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="46.5" cy="46.5" r="45.0469" stroke="#606062" stroke-width="2.90625" />
    <path
      d="M46 59.5938C53.5076 59.5938 59.5938 53.5076 59.5938 46C59.5938 38.4924 53.5076 32.4062 46 32.4062C38.4924 32.4062 32.4062 38.4924 32.4062 46C32.4062 53.5076 38.4924 59.5938 46 59.5938Z"
      stroke="#606062"
      stroke-width="3.625"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M46 25.1562V20.625" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M31.2531 31.2512L28.0586 28.0566" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M25.1562 46H20.625" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M31.2531 60.75L28.0586 63.9445" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M46 66.8438V71.375" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M60.7461 60.75L63.9406 63.9445" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M66.8438 46H71.375" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M60.7461 31.2512L63.9406 28.0566" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const BriefCaseIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="46.5" cy="46.5" r="45.0469" stroke="#606062" stroke-width="2.90625" />
    <path
      d="M65.9375 33.3125H26.0625C25.0615 33.3125 24.25 34.124 24.25 35.125V64.125C24.25 65.126 25.0615 65.9375 26.0625 65.9375H65.9375C66.9385 65.9375 67.75 65.126 67.75 64.125V35.125C67.75 34.124 66.9385 33.3125 65.9375 33.3125Z"
      stroke="#606062"
      stroke-width="3.625"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.0625 33.3125V29.6875C55.0625 28.7261 54.6806 27.8041 54.0008 27.1242C53.3209 26.4444 52.3989 26.0625 51.4375 26.0625H40.5625C39.6011 26.0625 38.6791 26.4444 37.9992 27.1242C37.3194 27.8041 36.9375 28.7261 36.9375 29.6875V33.3125"
      stroke="#606062"
      stroke-width="3.625"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.75 45.6152C61.1395 49.4383 53.6364 51.447 46 51.4379C38.3622 51.4578 30.8563 49.4484 24.25 45.6152"
      stroke="#606062"
      stroke-width="3.625"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M43.2812 44.1875H48.7188" stroke="#606062" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const BallonIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.125 40.5625C64.125 50.5766 56.0141 62.3125 46 62.3125C35.9859 62.3125 27.875 50.5766 27.875 40.5625C27.875 35.7555 29.7846 31.1453 33.1837 27.7462C36.5828 24.3471 41.193 22.4375 46 22.4375C50.807 22.4375 55.4172 24.3471 58.8163 27.7462C62.2154 31.1453 64.125 35.7555 64.125 40.5625V40.5625Z"
      stroke="#606062"
      stroke-width="3.625"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.4469 62.0859L51.4375 69.5625H40.5625L43.5531 62.0859"
      stroke="#606062"
      stroke-width="3.625"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.8125 29.8457C50.0396 30.2165 52.0951 31.2741 53.6915 32.8706C55.288 34.467 56.3456 36.5225 56.7164 38.7496"
      stroke="#606062"
      stroke-width="3.625"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="46.5" cy="46.5" r="45.0469" stroke="#606062" stroke-width="2.90625" />
  </svg>
);
export const GTCSIMPLEICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.0621 0.910211C26.7508 0.895468 18.7055 3.83888 12.366 9.21368C6.02656 14.5885 1.80666 22.0438 0.461513 30.2456C-0.883633 38.4473 0.733799 46.86 5.02434 53.9782C9.31487 61.0964 15.9985 66.4555 23.8791 69.0964C31.7596 71.7372 40.3228 71.4875 48.036 68.3919C55.7493 65.2962 62.1093 59.5568 65.9777 52.2006C69.8461 44.8445 70.9705 36.3518 69.1497 28.2424C67.3289 20.133 62.6817 12.9362 56.0397 7.94001L54.8479 10.1933C58.7648 13.2247 61.9357 17.1126 64.1177 21.5589C66.2997 26.0053 67.4349 30.8921 67.4363 35.845C67.4233 42.7357 65.2082 49.4417 61.1144 54.9844C57.0206 60.5271 51.2624 64.6163 44.6803 66.6551C39.8355 68.1569 34.7054 68.4977 29.7045 67.6498C24.7037 66.8019 19.9725 64.7892 15.8935 61.7743C11.8146 58.7595 8.50222 54.8272 6.22429 50.2952C3.94637 45.7633 2.76682 40.7589 2.78096 35.6868C2.77036 30.5187 4.00359 25.424 6.37651 20.8329C8.74944 16.2418 12.1924 12.2892 16.4145 9.30887C20.6366 6.32853 25.514 4.40801 30.6346 3.70952C35.7553 3.01102 40.9688 3.55506 45.8349 5.29569L47.0267 3.04243C43.1937 1.63812 39.1442 0.916455 35.0621 0.910211Z"
      fill="white"
    />
    <path
      d="M35.0074 5.91016C29.9695 5.90756 25.0179 7.24072 20.6406 9.77823C16.2633 12.3157 12.6114 15.97 10.0447 20.3809C7.47812 24.7918 6.08538 29.8071 6.0038 34.9324C5.92222 40.0578 7.15461 45.1163 9.57951 49.6095C12.0044 54.1026 15.5381 57.8753 19.8323 60.5557C24.1266 63.2362 29.0332 64.7318 34.0685 64.8952C39.1038 65.0586 44.094 63.8841 48.5474 61.4876C53.0008 59.091 56.7636 55.5551 59.465 51.2283L57.0388 49.9845C53.4393 55.7255 47.8766 59.9057 41.4251 61.7176C34.9737 63.5295 28.0913 62.8447 22.1071 59.7953C16.1229 56.7459 11.4616 51.5483 9.02341 45.2065C6.58525 38.8646 6.54323 31.8285 8.90548 25.457C11.2677 19.0854 15.8667 13.8306 21.814 10.7075C27.7613 7.58436 34.6351 6.81445 41.1077 8.54648C47.5803 10.2785 53.1925 14.3896 56.8603 20.0857C60.5281 25.7818 61.9913 32.6588 60.9673 39.3884L63.5148 40.6892C63.8332 38.9609 63.9956 37.2066 64 35.4481C63.9926 27.6266 60.9356 20.1275 55.5001 14.5968C50.0645 9.06612 42.6944 5.95567 35.0074 5.94813"
      fill="white"
    />
  </svg>
);

export const MAPMARKERICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 17.9102C18.2091 17.9102 20 16.1193 20 13.9102C20 11.701 18.2091 9.91016 16 9.91016C13.7909 9.91016 12 11.701 12 13.9102C12 16.1193 13.7909 17.9102 16 17.9102Z"
      stroke="#606062"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 13.9102C26 22.9102 16 29.9102 16 29.9102C16 29.9102 6 22.9102 6 13.9102C6 11.258 7.05357 8.71445 8.92893 6.83909C10.8043 4.96372 13.3478 3.91016 16 3.91016C18.6522 3.91016 21.1957 4.96372 23.0711 6.83909C24.9464 8.71445 26 11.258 26 13.9102V13.9102Z"
      stroke="#606062"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const DOUBLEARROWUPICON: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 26L16 16L26 26" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 16L16 6L26 16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
