export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none',
    padding: '0px'
  },
  overlay: {
    backgroundColor: 'transparent',
    zIndex: '1000'
  }
};

export const filterConfig: any = {
  matchFrom: 'start'
};

export const customSelectStyles = {
  control: (provided: any) => ({
    ...provided,

    borderRadius: '0px !important',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '5px',
    height: '45px !important',
    minHeight: '45px !important'
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none'
  }),
  menuList: (provided: any) => ({
    ...provided,
    overflow: 'hidden',
    maxHeight: '500px'
  }),
  menu: (provided: any) => ({
    ...provided,
    position: 'relative !important'
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    fontWeight: 'bold !important',
    fontSize: '14px',
    color: '#000',
    borderBottom: '#000 1px solid'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    marginLeft: '5px',
    marginTop: '-10px'
  }),
  placeholder: (provided: any) => ({
    ...provided
    // marginTop: '-16px'
  })
};

export const customDestinationSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    //  borderColor: 'rgba(82 82 82) !important',
    borderRadius: '0px !important',
    borderTopLeftRadius: '0px !important',
    borderBottomLeftRadius: '0px !important',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '5px',
    height: '2.5rem !important',
    minHeight: '2.5rem !important'
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none'
  }),
  menuList: (provided: any) => ({
    ...provided,
    overflow: 'hidden',
    maxHeight: '500px'
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    fontWeight: 'bold !important',
    fontSize: '14px',
    color: '#000',
    borderBottom: '#000 1px solid'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 0px',
    marginLeft: '5px',
    marginTop: '-10px'
  })
};

export const states: any = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const canadaStates: any = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Québec',
  SK: 'Saskatchewan',
  YT: 'Yukon'
};

export const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '5px solid #606062',
    maxHeight: '100%'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    zIndex: '1000',
    overflow: 'auto'
  }
};

export const modalGalleryCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '5px solid #fff',
    backgroundColor: '#6d6e71',
    maxHeight: '100%'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    zIndex: '1000',
    overflow: 'auto'
  }
};

export const GoogleRecpatchaKey = '6LcYiA0lAAAAAPi397xVpvupIrsMGs6FCIL3L9Bc';
