import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import AdvisorInspiration from './pages/advisorInspiration';
import Layout from './pages/layout/Index';
import { AppContextProvider } from './context/AppContext';

import Agents from './pages/agents';
import Agent from './pages/agent';
import Blog from './pages/blog';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThankYouEmail from './pages/thankyou';
import AboutUs from './pages/about-us';
import JoinUs from './pages/join-us';
import ThankYouNew from './pages/join-us/thankYouNew';
import ThankYouExperienced from './pages/join-us/thankYouExperienced';
import Career from './pages/career';
import BlogArticle from './pages/blogArticle';
import AdvisorOffers from './pages/advisor-offer';
import AdvisorOffer from './pages/advisor-offer/detail';
import SoarEmail from './pages/advisor-offer/soar';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import NotFound from './pages/notfound';
import Itinerary from './pages/itinerary';
import ContactUs from './pages/contact-us';
import ThankYouContactUs from './pages/contact-us/thankyou';
import SeenInMedia from './pages/advisor-offer/seenInMedia';

function App() {
  const getCanonical = () => {
    if (window.location.search.includes('utm')) {
      const params = window.location.search
        .slice(1)
        .split('&')
        .reduce((acc, s) => {
          const [k, v] = s.split('=');
          if (k.includes('utm') || k.includes('gclid')) {
            return acc;
          }
          return Object.assign(acc, { [k]: v });
        }, {} as any);

      return `${window.location.toString().split('?')[0]}?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`;
    }
    return window.location.toString();
  };
  useEffect(() => {
    if (window.location.search.includes('utm')) {
      const params = window.location.search
        .slice(1)
        .split('&')
        .reduce((acc, s) => {
          const [k, v] = s.split('=');
          if (!k.includes('utm') && !k.includes('gclid')) {
            return {};
          }
          return Object.assign(acc, { [k]: v });
        }, {});
      const cookies = new Cookies();
      cookies.set('google-utm', JSON.stringify(params), {
        expires: new Date(Date.now() + 60 * 60 * 24 * 1000 * 7),
        path: '/'
      });
    }
  }, []);

  return (
    <>
      <a href="#main" className="skip-to-main-content-link">
        Skip to main content
      </a>
      <Helmet>
        <link rel="canonical" href={getCanonical()} />
      </Helmet>
      <div id="main">
        <AppContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/find-an-elite-travel-agent" element={<Agents />} />
                <Route path="/advisor-inspiration" element={<AdvisorInspiration />} />
                <Route path="/travel-agent/agent-blog" element={<Blog />} />
                <Route path="/travel-agent/email-agent/thank-you" element={<ThankYouEmail />} />
                <Route path="/travel-agent/:id" element={<Agent />} />
                <Route path="/travel-agent/:id/itinerary/:itineraryId" element={<Itinerary />} />
                <Route path="/travel-agent/:id/:name" element={<Agent />} />
                <Route path="/travel-agent/:id/:name/:blog" element={<Agent />} />
                <Route path="/about-gtc" element={<AboutUs />} />
                <Route path="/join-us" element={<JoinUs />} />
                <Route path="/thankyou-new-agent" element={<ThankYouNew />} />
                <Route path="/thankyou-exprienced-agent" element={<ThankYouExperienced />} />
                <Route path="/careers" element={<Career />} />
                <Route path="/advisor-inspiration/story/:id/:slug" element={<BlogArticle />} />
                <Route path="/advisor-offers" element={<AdvisorOffers />} />
                <Route path="/advisor-offers/offer/:id" element={<AdvisorOffer />} />
                <Route path="/advisor-offers/offer/:id/:slug" element={<AdvisorOffer />} />
                <Route path="/advisor-offers/soar/:id/:slug" element={<SoarEmail />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/thank-you-contact-us" element={<ThankYouContactUs />} />
                <Route path="/itke-agents" element={<Agents type="ITKE" />} />
                <Route path="/as-seen-in-media" element={<SeenInMedia />} />

                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </AppContextProvider>
      </div>
    </>
  );
}

export default App;
