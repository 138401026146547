import { useSwiper } from 'swiper/react';
import { SwipperButtonProps } from './SwiperButtonNext';

export const SwiperButtonPrev = (prop: SwipperButtonProps) => {
  const swiper = useSwiper();
  return (
    <button
      aria-label="Previous button"
      onClick={() => swiper.slidePrev()}
      className={[
        'bg-white  -ml-2 lg:-ml-4 flex justify-center items-center w-[50px] h-[50px] rounded-full shadow focus:outline-none border border-black',
        prop.className
      ].join(' ')}>
      <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 18.9812L1 9.9994L11 1.01758" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
  );
};
