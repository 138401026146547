import { Link, useLocation } from 'react-router-dom';
import HeaderLink, { HeaderLinkProps } from '../../components/HeaderLink';
import React, { useEffect, useState } from 'react';

const headerLinks: HeaderLinkProps[] = [
  {
    name: 'About Us',
    to: 'about-gtc'
  },
  {
    name: 'Journal',
    to: 'advisor-inspiration'
  },
  {
    name: 'Offers',
    to: 'advisor-offers'
  },
  {
    name: 'Find An Advisor',
    to: 'find-an-elite-travel-agent',
    hasBorder: true
  }
];
const headerLinksMobile: HeaderLinkProps[] = [
  {
    name: 'Find An Advisor',
    to: 'find-an-elite-travel-agent',
    hasBorder: true
  },
  {
    name: 'About Us',
    to: 'about-gtc'
  },
  {
    name: 'Journal',
    to: 'advisor-inspiration'
  },
  {
    name: 'Offers',
    to: 'advisor-offers'
  }
];

function Header() {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  useEffect(() => {
    setShowMenu(false);
  }, [pathname]);
  return (
    <>
      <nav className="w-full  text-white bg-transparent py-2 relative z-50" id="header">
        <div className="px-8 py-3 md:flex flex-col  mx-auto md:flex-row hidden-tw">
          <Link className="flex items-center" to="/">
            <img
              src="/images/logo-GTC-black.svg"
              alt="GTC Logo"
              title="GTC Logo"
              width={300}
              height={62}
              aria-label="GTC Logo"
              tabIndex={0}
              role="contentinfo"
              className="w-48 inline-block cursor-pointer"
            />
          </Link>
          <nav className="flex items-center justify-center text-base md:ml-auto gap-1.5 uppercase">
            {headerLinks.map((link, index) => (
              <React.Fragment key={link.name}>
                <HeaderLink {...link} />
                {index + 1 < headerLinks.length && <span className="font-medium text-slate-400"></span>}
              </React.Fragment>
            ))}
          </nav>
        </div>
        <div className="px-4 md:hidden py-4  mx-auto flex flex-row justify-between ">
          <Link className="flex items-center" to="/">
            <img
              src="/images/logo-GTC-black.svg"
              alt="GTC LOGO"
              title="GTC LOGO"
              width={300}
              height={62}
              aria-label="GTC Logo"
              tabIndex={0}
              role="contentinfo"
              className="w-40 inline-block cursor-pointer"
            />
          </Link>
          <button
            data-collapse-toggle="mobile-menu-2"
            type="button"
            className="inline-flex items-center  text-sm text-black rounded-lg lg:hidden bg-transparent focus:outline-none "
            aria-controls="mobile-menu-2"
            onClick={() => setShowMenu(!showMenu)}
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            {!showMenu ? (
              <svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="2" fill="#434447" />
                <rect y="7" width="30" height="2" fill="#434447" />
                <rect y="14" width="30" height="2" fill="#434447" />
              </svg>
            ) : (
              <svg role="presentation" className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"></path>
              </svg>
            )}
          </button>
          {showMenu && (
            <nav
              className="flex flex-col absolute top-12 bg-black py-5 px-4 right-0 w-fit
           text-base md:ml-auto gap-5 uppercase z-50 "
              style={{ background: 'rgba(0,0,0,0.5)', zIndex: '1000' }}>
              {headerLinksMobile.map((link) => (
                <React.Fragment key={link.name}>
                  <HeaderLink {...link} isMobile />
                </React.Fragment>
              ))}
            </nav>
          )}
        </div>
      </nav>
    </>
  );
}

export default Header;
